import React from 'react';
import { Link } from 'react-router-dom';

import CryptoAddressCopyButton from '../components/copy_clipboard';

function HowToBuy() {
    const cryptoAddress = '0x7362ca37FFA0A3d64385CCF6AC6EA828eC3756fa';
    return (
        <div className="how_to_buy">
            <div className="how_to_buy_container"> 
                <div className="row_txt">
                    <div className="full_width">
                        <p>To purchase BCOAL directly with your Debit/Credit Card or Apple Pay please click the payment link below. Once completed you will receive an email within 24 hours asking for your wallet address to send you the BCOAL tokens.</p>

                        <Link className="cta_btn" target='_blank' to="https://buy.stripe.com/14kcQw4xD4s9bXq8wx">Direct Payment Link</Link> 
                    </div>
                </div> 
            </div>
        </div>
    )
}

export default HowToBuy;