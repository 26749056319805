import stellar_img_1 from '../assets/images/stellar_token_image.webp';
import stellar_img_2 from '../assets/images/stella2s.png';

const Ethereum = ()=> {
    return (
        <div className="stellar">
            <h2>Stellar</h2>
            <div className="stellar_row_content">
                <div className="txt_side">
                    <h3> The Stellar Network</h3>
                    <p>Stellar empowers builders to unlock human and economic potential. It combines a powerful, decentralized blockchain network with a global ecosystem of innovators to create opportunities as borderless as ideas. It offers the tools to make a difference in the real world through new digital asset products and services that enhance access to the global financial system.</p>
                    <p>Stellar is a decentralized, public blockchain that gives developers the tools to create experiences that are more like cash than crypto. The network is faster, cheaper, and far more energy-efficient than most blockchain-based systems. It’s designed so Stellar’s ecosystem can make a real-world, lasting impact.</p>
                </div>
                <div className="stellar_img"><img src={stellar_img_1} /></div>
            </div>
            <h2>BCOAL on the Stellar Network</h2>
            <div className="stellar_row_content align_left">
                <div className="stellar_img"><img src={stellar_img_2} /></div>
                <div className="txt_side">
                    <h3>STELLAR IS BUILT FOR ASSET TOKENIZATION</h3>
                    <p>Native platform features that make Stellar the network optimized for asset issuance.</p>
                    <br />
                    <h3>Fast</h3>
                    <p>Transactions on the Stellar network confirm in just seconds.</p>
                    <br />
                    <h3>Low Cost</h3>
                    <p>The average cost per transaction on the Stellar network is a fraction of a cent per operation.</p>
                    <h3>Compliance and Controls</h3>
                    <p>Built-in account level features enable custom asset controls, such as KYC, Freeze and Clawback functionality.</p>
                    <br />
                    <h3>Sustainable</h3>
                    <p>Efficient by design, the Stellar network's low energy consumption makes it one of the most sustainable blockchains.</p>
                    <br />
                    <h3>Quick to Market</h3>
                    <p>Leverage the Stellar network's built-in DEX, robust tooling, and network of trusted service providers to get to market with ease.</p>
                    <br />
                    <h3>Scalable</h3>
                    <p>Transactions on the Stellar network can reliably scale to meet your business needs.</p>
                </div>
            </div>
        </div>
    )
}
export default Ethereum;