const Redemption = ()=> {
    return (
        <div className="redemption">
            <div className="redemption_container">
                <h1>Redemption Eligibility Information</h1>
                <p>Delivery of BCOAL for token holders is subject to a compliance protocol, whereby those electing to take delivery must satisfy eligibility requirements for their respective jurisdictions. This process is managed by Good Guyz Investments along with their consultants to ensure regulatory adherence before accepting delivery requests from eligible buyers. Eligibility for delivery of coal requires ownership of a minimum of 25,000 tokens, representing 25,000 tons of BCOAL. Taking physical delivery of purchased Illinois Basin Coal, also known as Bituminous coal, is subject to a regulatory process due to its potential of combustion and the associated risks to health, safety, and the environment. While the regulatory process may vary depending on the jurisdiction, some common steps are involved.</p>
                <h4>Licensing</h4>
                <p>The buyer must obtain relevant permits or licenses from the Illinois Department of Environmental Services The licensing process typically involves providing detailed information about intended use, storage facilities, and transport arrangements.</p>
                <h4>Export/Import Controls</h4>
                <p>Coal transactions often involve cross-border transfers. In such cases, both the exporting and importing countries may have additional export and import controls in place.</p>
                <h4>Transport</h4>
                <p>Transporting coal requires special containers that meet safety and security standards. The buyer must coordinate with certified carries and follow the guidelines established by the EPA.</p>
                <h4>Environmental Compliance</h4>
                <p>Buyers must ensure that their operation comply with applicable environmental regulations to protect public health and the environment.</p> 
            </div>
        </div>
    )
}

export default Redemption;