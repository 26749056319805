import React from 'react';
import { Button, message } from 'antd';
import { CopyOutlined, CheckOutlined } from '@ant-design/icons'; // Import the copy and check icons
import Clipboard from 'clipboard';

class CryptoAddressCopyButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false
    };
    this.buttonRef = React.createRef();
    this.clipboard = null;
  }

  componentDidMount() {
    this.clipboard = new Clipboard(this.buttonRef.current, {
      text: () => this.props.address,
    });
    this.clipboard.on('success', () => {
      this.setState({ copied: true });
      message.success('Address copied to clipboard');
    });
    this.clipboard.on('error', () => {
      message.error('Failed to copy address');
    });
  }

  componentWillUnmount() {
    if (this.clipboard) {
      this.clipboard.destroy();
    }
  }

  render() {
    const { copied } = this.state;

    return (
      <Button
        ref={this.buttonRef}
        className="copy-button"
        type="primary"
        icon={copied ? <CheckOutlined /> : <CopyOutlined />}
      >
        {this.props.address}
      </Button>
    );
  }
}

export default CryptoAddressCopyButton;
