import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './components/header';
import Home from './pages/home';
import Footer from './components/footer'; 
import Stellar from './pages/stellar';
import Redemption from './pages/redemption'
import LOBSTRWallet from './pages/lobstr-wallet';
import HowToBuy from './pages/how-to-buy';
import DirectLink from './pages/direct-link';

function App() {
  return ( 
    <Router>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} /> 
        <Route path='/how-to-buy' element={<LOBSTRWallet />} /> 
        <Route path='/stellar' element={<Stellar />} /> 
        <Route path='/redemption' element={<Redemption />} /> 
        {/* <Route path='/how-to-buy' element={<HowToBuy />} /> */}
        <Route path='/direct-link' element={<DirectLink />}/>
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;