import React, { useState } from 'react';
import Logo from '../assets/images/logo.png';

import MenuImageOpen from '../assets/icons/menu/menu.svg';
import MenuImageClose from '../assets/icons/menu/close.svg'; 
import white_paper from '../assets/BCOAL-WHITE-PAPERUpdatedFeb2024.pdf'

const Header = ()=> {
    const [menu_icon, setMenuIcon] = useState('closed');
    function getDate() { 
        const currentDate = new Date(); 
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1;  
        const year = currentDate.getFullYear();  
        return `${month}/${day}/${year}`;
    }
    return (
        <header className="header">
            <a href="/" className='link_logo'><img src={Logo} className='header_logo'/></a>
            <nav className={menu_icon}>
                <a href="/#whatisbcoal">What is BCOAL</a>
                <a href="/stellar">Stellar Network </a> 
                <a href="/how-to-buy">How to buy</a>
                <a href="/direct-link">Direct Payment</a>
                {/* <small className='hide_desktop'>To purchase BCOAL directly with your Debit/Credit Card or Apple Pay please click the payment link below. One completed you will receive an email within 24 hours asking for your wallet address to send you the BCOAL tokens.</small> */}
                {/* <a href="https://buy.stripe.com/14kcQw4xD4s9bXq8wx" className="cta_btn">Direct Payment Link</a> */}
                <a href={white_paper}>Whitepaper</a>
                <a href="/redemption">Redemption</a>
                <a href="/#ourteam">Our Team</a>
                <a href="/#aboutus">About Us</a>
            </nav>
            <div className="us_dollar">
                <span>us$ 45</span>
                <span className='up_arr'>▲</span>
                <small>Date: {getDate()}</small>
            </div>

            {
                menu_icon == "opened" && (
                    <img src={MenuImageClose} className="img_icon" onClick={()=> { setMenuIcon('closed') }} />
                )
            }
            {
                menu_icon == "closed" && (
                    <img src={MenuImageOpen} className="img_icon" onClick={()=> { setMenuIcon('opened') }} />
                )
            }
        </header>
    )
}

export default Header;