import how_to_buy_image from '../assets/images/how_to_buy.png';
import step1 from '../assets/images/steps/step_1.png';
import step2 from '../assets/images/steps/step_2.png';
import step3 from '../assets/images/steps/step_3.png';
import step4 from '../assets/images/steps/step_4.png';
import step5 from '../assets/images/steps/step_5.png';
import step6 from '../assets/images/steps/step_6.png';
import step7 from '../assets/images/steps/step_7.png';  
//////////////////////////////////////////////////////
import create_acc_1 from '../assets/images/steps/create_account_step_1.png'
import create_acc_2 from '../assets/images/steps/create_account_step_2.png'
import create_acc_3 from '../assets/images/steps/create_account_step_3.png'
import create_acc_4 from '../assets/images/steps/create_account_step_4.png'
import create_acc_5 from '../assets/images/steps/create_account_step_5.png'
//////////////////////////////////////////////////////
import existing_wallet1 from '../assets/images/steps/connect_existing_1.png';
import existing_wallet2 from '../assets/images/steps/connect_existing_2.png';
import existing_wallet3 from '../assets/images/steps/connect_existing_3.png';
import existing_wallet4 from '../assets/images/steps/connect_existing_4.png'; 
//////////////////////////////////////////////////////
import lobstr1 from '../assets/images/steps/connect_lobstr_1.png';
import lobstr2 from '../assets/images/steps/connect_lobstr_2.png';

const LOBSTRWallet = ()=> {
    return (
        <div className="how_to_buy">
            <div className="how_to_buy_container">
                <h1>How to Buy BCOAL?</h1>
                <img src={how_to_buy_image} className='how_to_buy_image' />
                <h2>LOBSTR Wallet</h2> 
                <div className="wallet_cards">
                    <div className="single_card">
                        <h3>1,000,000+ Total Users</h3>
                        <p>Intuitive design and user-friendly interface make LOBSTR the go-to choice for beginners and pros. Easily send, receive, and trade your crypto.</p>
                    </div>
                    <div className="single_card">
                        <h3>4.75 Rating on Google Play & App Store</h3>
                        <p>Lightning-fast and affordable transactions with some of the lowest fees on the market, providing the seamless digital experience you deserve.</p>
                    </div>
                    <div className="single_card">
                        <h3>5,000,000+ Monthly Transactions</h3>
                        <p>Intuitive design and user-friendly interface make LOBSTR the go-to choice for beginners and pros. Easily send, receive, and trade your crypto.</p>
                    </div>
                </div>
                <p></p>
                <p>You can create a new LOBSTR account free of charge in just a few clicks through our website or mobile apps available for iOS and Android.</p>
                <p>Scroll down to the 2nd part of the article to see the steps needed to connect an existing Stellar wallet to your LOBSTR account. </p>
                {/* <h2>Steps to Create Account in Lobstr Website</h2>
                <div className="row_txt">
                    <div className="txt_side">
                        <h3>1. Go to the LOBSTR website and click the 'Get Started' button.</h3>
                    </div>
                    <img src={step1} className='img_side' />
                </div>
                <div className="row_txt">
                    <div className="txt_side">
                        <h3>2. Insert your email address and choose a strong password</h3>
                        <p>We recommend using a password manager (like 1Password, Lastpass, Dashlane, Keeper, etc) to help you generate and remember your password.</p>
                    </div>
                    <img src={step2} className='img_side' />
                </div>
                <div className="row_txt">
                    <div className="txt_side">
                        <h3>3. Verify your account by clicking on the 'Verify Email'</h3>
                        <p>in the message sent to your email address.</p>
                    </div>
                    <img src={step3} className='img_side' />
                </div> 
                <div className="row_txt">
                    <div className="txt_side">
                        <h3>4. Press the 'Create Wallet' button</h3>
                        <p>if you’d like to create a new Stellar wallet.</p>
                    </div>
                    <img src={step4} className='img_side' />
                </div>
                <div className="row_txt">
                    <div className="txt_side">
                        <h3>5. Write down your Recovery Phrase</h3>
                        <p>Write down your Recovery Phrase, which is needed to recover access to your account in case of a password loss or if your phone is lost or stolen. The word order is very important.</p>
                        <p>You are the only one who has access to the Recovery Phrase.We (the LOBSTR team) do not have access to users’ Recovery phrase</p>
                    </div>
                    <img src={step5} className='img_side' />
                </div>
                <div className="row_txt">
                    <div className="txt_side">
                        <h3>6. (Optional) Set a federation address</h3>
                        <p>which is the unique name of your new Stellar wallet that can be shared with others to receive funds.</p>
                    </div>
                    <img src={step6} className='img_side' />
                </div>
                <div className="row_txt">
                    <div className="txt_side">
                        <h3>7. Transfer at least 1 XLM to your wallet to activate it.</h3>
                        <p>To purchase Stellar lumens with LOBSTR, you can use the ‘Buy Lumens’ option on the LOBSTR web and mobile apps.Note: Services relating to credit card payments are provided by Moonpay, which is a separate platform owned by a third party</p>
                    </div>
                    <img src={step6} className='img_side' />
                </div> */}
                <h2>Steps to Create Account in Lobstr Mobile App</h2>
                <div className="row_txt">
                    <div className="txt_side">
                        <h3>1. Open Lobstr Mobile App</h3>
                        <p>Tap the ‘Create Account’ button, enter your email address, and choose a strong and complex password.​</p>
                    </div>
                    <img src={create_acc_1} className='img_side' />
                </div>
                <div className="row_txt">
                    <div className="txt_side">
                        <h3>2. Press the 'Verify Email'</h3>
                        <h3>3. Tap the 'Create Stellar Wallet' button.</h3>
                    </div>
                    <img src={create_acc_2} className='img_side' />
                </div>
                <div className="row_txt">
                    <div className="txt_side">
                        <h3>4. Write down your Recovery Phrase</h3>
                        <p>and confirm it at the next step.</p>
                    </div>
                    <img src={create_acc_3} className='img_side' />
                </div>
                <div className="row_txt">
                    <div className="txt_side">
                        <h3>Note: This step is needed to recover access to your account in case your phone is lost or stolen.</h3>
                        <p>The word order is very important. Make sure you’re entering the Recovery Phrase in the correct order without any spelling mistakes.</p>
                    </div>
                    <img src={create_acc_4} className='img_side' />
                </div>
                <div className="row_txt">
                    <div className="txt_side">
                        <h3>5. (Optional) Set a federation address</h3>
                        <p>which is the unique name of your new Stellar wallet that can be shared with others to receive funds.</p>
                        <h3>6. Transfer at least 1 XLM to your wallet to activate it.</h3>
                        <p>To purchase Stellar lumens with LOBSTR, you can use the ‘Buy Lumens’ option on the LOBSTR web and mobile apps</p>
                    </div>
                    <img src={create_acc_4} className='img_side' />
                </div>
                <h2>Steps to Connect Existing Wallet in Lobstr Web</h2>
                <div className="row_txt">
                    <div className="txt_side">
                        <h3>1. Click the 'Connect Existing Wallet'</h3>
                        <p>button under the Wallet tab in Settings.</p>
                    </div>
                    <img src={existing_wallet1} className='img_side' />
                </div>
                <div className="row_txt">
                    <div className="txt_side">
                        <h3>2. Choose the preferred option to add the wallet.</h3>
                    </div>
                    <img src={existing_wallet2} className='img_side' />
                </div>
                <div className="row_txt">
                    <div className="txt_side">
                        <h3>Option 1. Connect the wallet</h3>
                        <p>
                            with your public Stellar address and Secret key
                            <br/>
                            The Secret key is optional. If it is not specified, your account will be available in the preview mode only. You won’t be able to submit transactions without it.
                        </p>
                    </div>
                    <img src={existing_wallet3} className='img_side' />
                </div>
                <div className="row_txt">
                    <div className="txt_side">
                        <h3>Option 2. Connect the wallet with your Recovery Phrase</h3>
                    </div>
                    <img src={existing_wallet4} className='img_side' />
                </div>
                <h2>Steps to Connect Existing Wallet in Lobstr App</h2>
                <div className="row_txt">
                    <div className="txt_side">
                        <h3>1. Tap the 'Connect Stellar Wallet' button</h3>
                        <p>and choose the preferred option to add an existing wallet.</p>
                    </div>
                    <img src={lobstr1} className='img_side' />
                </div>
                <div className="row_txt">
                    <div className="txt_side">
                        <h3>Option 1. Connect the wallet</h3>
                        <p>
                            using your public Stellar address and Secret key.<br/>
                            If your Secret key is not specified, your account will be available in the preview mode only. You won’t be able to submit transactions without it.
                        </p>
                        <h3>Option 2. Connect the wallet using your Recovery Phrase</h3>
                    </div>
                    <img src={lobstr2} className='img_side' />
                </div>
            </div>
            <div className="youtube_banner">
                <h2>Accept Stellar Network Trustline Using a QR code for BCOAL</h2>
                <p>Below is they hyperlink to a YouTube on how to add BCOAL with a QR code</p>
                <iframe width="720" height="500" src="https://www.youtube.com/embed/H1AikBLrEM8" title="Accept a Stellar Network Trustline Using a QR Code" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div> 
        </div>
    )
}
export default LOBSTRWallet;